import React, { Component } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { groupBy } from 'lodash'
import { Box, Button, Large, colors, Caret } from 'js-components'
import { Section, WrapperBackground } from '../components/Wrapper'
import { CallToAction, GetStarted } from '../components/CallToAction'
import { mobileBreakpoint } from '../constants'
import {
  PageTitle,
  PageParagraph,
  PageRegular,
  PageLabel,
  PageTitle3,
} from '../components/PageTitle'
import Capture from './images/capture.svg'
import Understand from './images/understand.svg'
import TrustCircleGreen from './images/check-circle-green.svg'
import TrustCircleBlue from './images/check-circle-blue.svg'
import DealIcon from './images/dealIcon.svg'
import TrustCirclePurple from './images/check-circle-purple.svg'
import Default from '../components/Layouts/Default'
import { Helmet } from 'react-helmet'
import { requestDemo } from '../common/Util'

export const query = graphql`
  query featureQuery {
    captureFeatures: allPricingCsv(
      filter: { type: { eq: "featured" }, capture: { eq: "X" } }
    ) {
      edges {
        node {
          name
        }
      }
    }
    understandFeatures: allPricingCsv(
      filter: { type: { eq: "featured" }, understand: { eq: "X" } }
    ) {
      edges {
        node {
          name
        }
      }
    }
    actFeatures: allPricingCsv(
      filter: { type: { eq: "act" }, understand: { eq: "X" } }
    ) {
      edges {
        node {
          name
        }
      }
    }
    features: allPricingCsv(filter: { type: { ne: "featured" } }) {
      edges {
        node {
          name
          type
          capture
          understand
          act
        }
      }
    }
  }
`

// TODO make this shared with gatsby-node.js
const getUrl = name => {
  return `../features/${name
    .replace(/[^\w\s]/gi, '')
    .replace(/\s+/g, '-')
    .toLowerCase()}`
}

const FeatureList = ({ features }) => {
  const featureMap = groupBy(
    features.edges.map(f => f.node),
    node => node.type,
  )
  const sections = Object.keys(featureMap)
  return (
    <Section flexDirection="column">
      {sections.map(section => (
        <PricingFeaturesList key={section}>
          <PricingFeaturesListHeader>
            <PricingHeader>{section}</PricingHeader>
            <PricingFeatureBlue>Capture</PricingFeatureBlue>
            <PricingFeatureGreen>Understand</PricingFeatureGreen>
            <PricingFeaturePurple>Act</PricingFeaturePurple>
          </PricingFeaturesListHeader>
          {featureMap[section].map(feature => (
            <PricingFeaturesListElement
              key={feature.name}
              href={getUrl(feature.name)}>
              <PricingHeader color="trulyDark">{feature.name}</PricingHeader>
              <PricingFeatureValid>
                {feature.capture && <img src={TrustCircleBlue} alt="Capture" />}
              </PricingFeatureValid>
              <PricingFeatureValid>
                {feature.understand && (
                  <img src={TrustCircleGreen} alt="Understand" />
                )}
              </PricingFeatureValid>
              <PricingFeatureValid>
                {feature.act && <img src={TrustCirclePurple} alt="Act" />}
              </PricingFeatureValid>
              <PricingCaret orientation="right" />
            </PricingFeaturesListElement>
          ))}
        </PricingFeaturesList>
      ))}
    </Section>
  )
}

export default class PricingPage extends Component {
  render() {
    return (
      <Default>
        <Helmet>
          <meta charset="UTF-8" />
          <title>Pricing</title>
          <meta
            name="description"
            content="Stop being surprised by your phone bill. Truly includes unlimited phone numbers, minutes (both domestic and international), and local presence."
          />
        </Helmet>
        <WrapperBackground
          color={colors.trulyDark}
          background="sf"
          gradientType="purpleToGreen">
          <Section flexDirection="column">
            <Box mt="2rem" width={[1, 3 / 4]}>
              <PageTitle center color={colors.white}>
                Pricing Designed To Drive Revenue, Not Costs
              </PageTitle>
              <PageParagraph
                as="p"
                color={colors.white}
                mb="1.5rem"
                mt="1.5rem">
                Only pay for users who are communicating with customers. <br />
                Everyone else is FREE.
              </PageParagraph>
            </Box>
            <a href="https://hello.truly.co/book-a-meeting">
              <PromoContainer>
                <img src={DealIcon} />
                <PageParagraph
                  as="p"
                  color={colors.white}
                  ml="8px"
                  mb="1.5rem"
                  mt="1.5rem">
                  SPECIAL OFFER: Unlimited Plans with access to all features
                  starting at $99/user/month
                </PageParagraph>
              </PromoContainer>
            </a>
          </Section>

          <Section>
            <PricingCardSection>
              <PricingCard>
                <PricingCardContent>
                  <PricingCardTopSection>
                    <PricingCardIconWrapper>
                      <PricingCardIcon src={Capture} />
                    </PricingCardIconWrapper>
                    <PricingCardHeader color={colors.accentAqua}>
                      Capture
                    </PricingCardHeader>
                    <PricingCardPricing color={colors.accentFog}>
                      Capture all of your
                      <br />
                      activity data in CRM
                    </PricingCardPricing>
                    <PricingCardButton
                      size="extraLarge"
                      round
                      type="outline"
                      value="Start 7 Day Trial"
                      onClick={requestDemo}
                      color={colors.accentFog}
                    />
                  </PricingCardTopSection>
                  <PricingCardListElementHeader>
                    Voice
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Phone System
                    <br />
                    Sales Dialer
                    <br />
                    Contact Center/IVR
                    <br />
                    All Integrations & APIs
                    <br />
                    Mobile & Desktop Apps
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    Starting at $45/user/month
                  </PricingCardListElementPricing>

                  <PricingCardListElementHeader>
                    Video
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Meeting Recorder Bot
                    <br />
                    Zoom, MSFT Teams
                    <br />
                    Salesforce Integration & APIs
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    $25/user/month
                  </PricingCardListElementPricing>

                  <PricingCardListElementHeader>
                    Messaging
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Group Texting
                    <br />
                    Picture Messaging
                    <br />
                    Salesforce Integration & APIs
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    $10/user/month
                  </PricingCardListElementPricing>

                  <PricingCardListElementHeader>
                    Email
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Email Tracking
                    <br />
                    Salesforce Integration
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    $15/user/month
                  </PricingCardListElementPricing>

                  <PricingCardListElementHeader>
                    Calendar Event Logging
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Calendar Integration
                    <br />
                    Salesforce Integration
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    $10/user/month
                  </PricingCardListElementPricing>

                  <PricingCardListElementHeader>
                    Artificial Intelligence
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Call Transcription
                    <br />
                    Sentiment Analysis
                    <br />
                    AI Summarization
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    $40/user/month
                  </PricingCardListElementPricing>
                </PricingCardContent>
              </PricingCard>
              <PricingCard>
                <PricingCardContent>
                  <PricingCardTopSection>
                    <PricingCardIconWrapper>
                      <PricingCardIcon src={Understand} />
                    </PricingCardIconWrapper>
                    <PricingCardHeader color={colors.accentSage}>
                      HYPERAUTOMATION
                    </PricingCardHeader>
                    <PricingCardPricing color={colors.accentClover}>
                      AUTOMATE ANY <br/>PROCESS WITH BOTS
                    </PricingCardPricing>
                    <PricingCardButton
                      size="extraLarge"
                      round
                      type="outline"
                      value="Custom Pricing"
                      onClick={requestDemo}
                      color={colors.accentSage}
                    />
                  </PricingCardTopSection>

                  <PricingCardListElementHeader>
                    Prospecting Automation
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Monitor Job Postings
                    <br />
                    Track Job Changers
                    <br />
                    Prospect On Linkedin
                    <br />
                    Guess & Find Contact Info
                    <br />
                    Robotic Email & Phone Validation
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    Starting at $1000/month
                  </PricingCardListElementPricing>                  

                  <PricingCardListElementHeader>
                    AI Enrichment
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Sentiment Analysis
                    <br />
                    Zero Shot Classification
                    <br />
                    Advanced Text Analysis
                    <br />
                  </PricingCardListElement>
                  <PricingCardListElementPricing>
                    Starting at $250/month
                  </PricingCardListElementPricing>   

                  <PricingCardListElementHeader>
                    Salesforce Automation
                  </PricingCardListElementHeader>
                  <PricingCardListElement>
                    Text Analytics
                    <br />
                    Data Modeling
                    <br />
                    Rules Engine         
                    <br />
                    Task Automation
                    <br />
                    Prebuilt API Connectors
                  </PricingCardListElement>

                  <PricingCardListElementPricing>
                    Starting at $500/month
                  </PricingCardListElementPricing>
                </PricingCardContent>
              </PricingCard>
            </PricingCardSection>
          </Section>
        </WrapperBackground>

        <WrapperBackground background="sf" gradientType="light">
          <Section
            flexDirection={['column', 'row']}
            mt="0"
            m={['1rem', '2rem', '5rem']}>
            <Box width={[1, 1]} mb="16pt">
              <PageTitle center color={colors.accentLavender} mb="4pt">
                Frequently Asked Questions
              </PageTitle>
              <PageTitle3>
                Our pricing philosophy is simple. Be transparent, deliver
                unquestionable value and make scaling easy.
              </PageTitle3>
            </Box>
            <FaqSection>
              <FaqPanel>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    What is a User?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    We only charge for sales users who actively engage with
                    customers and have their activity/opportunity data analyzed.
                    We do not charge for admins or recording listeners.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Do you have a user minimum?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    We do not have a user minimum. Our activity tracking & AI
                    products save reps 5 hours/week on teams of all sizes.
                    However, we recommend our Process Mining product for teams
                    with 20 users or more, as customers see exponential benefits
                    with higher data volume.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Do you allow free trials or pilots?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    {' '}
                    We offer self-service or guided trials. You can customize
                    the trial period by talking to product consultant.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Do you require an annual commitment?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    Yes. We are happy to customize your trial as long as
                    reasonable to ensure you are 100% confident. However, we
                    then require an annual commitment so we can afford to invest
                    in your success.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Can I pay monthly/Quarterly?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    Our list pricing is reduced 25% to reflect up-front
                    payments. Monthly/Quarterly payments are possible but costs
                    more. We recommend taking advantage of our trials to de-risk
                    your purchase and pay upfront.
                  </PageRegular>
                </TextWrapper>
              </FaqPanel>
              <FaqPanel>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Do you customize pricing?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    No. We love all our customers and offer the same fair,
                    transparent pricing to everyone. If you are locked into a
                    contract, we can work with you to make the transition
                    easier.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    What forms of payment do you accept?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    You can purchase Trulywith any major credit card. For
                    subscriptions over $10K, we can issue an invoice payable by
                    ACH. Please contact us to arrange an invoice purchase.
                    Monthly purchases must be paid for by credit card.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Is there an implementation fee?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    No. Implementation/training is complimentary throughout your
                    subscription period as a Truly customer.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Do you offer an MSA for Legal Review?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    Our Master Services Agreement is very straightforward and
                    can be found{' '}
                    <a href="https://truly.co/msa" target="_blank">
                      <u>here</u>
                    </a>
                    . We can customize our MSA for subscriptions over $24K/year.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Do you offer uptime guarantees?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    Yes. We offer customers a full month's statement credit for
                    users who experience less than 99.95% uptime. Our 2 year
                    trailing uptime has been 99.995%.
                  </PageRegular>
                </TextWrapper>
                <TextWrapper>
                  <PageLabel color={colors.accentLavender} mb="4pt">
                    Are you SOC II certified?
                  </PageLabel>
                  <PageRegular color={colors.trulyDark}>
                    Yes. We are fully SOC II Type 2 certified and are proud to
                    work with customers who have the highest standards like
                    banks and healthcare systems.{' '}
                  </PageRegular>
                </TextWrapper>
              </FaqPanel>
            </FaqSection>
          </Section>
        </WrapperBackground>

        <WrapperBackground background="headsetPerson1" gradientType="black">
          <CallToAction />
        </WrapperBackground>

        <WrapperBackground background="sf" gradientType="purple">
          <GetStarted />
        </WrapperBackground>
      </Default>
    )
  }
}

const PricingCardSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

const FaqSection = styled.div`
  display: flex;
  align-items: top;
  flex-direction: row;

  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

const FaqPanel = styled.div`
  width: 50%;
  padding: 0 16px 0 16px;
  flex-grow: 0;

  @media (max-width: 1280px) {
    width: 100%;
  }
`

const PricingCardButton = styled(Button)`
  margin: 10px;
  text-transform: uppercase;
  padding: 12px 32px 10px 32px;
  font-weight: bold;
  transition: all 0.2s;

  :hover {
    transform: scale(1.05);
    transition: all 0.2s;
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 16pt 16pt 32pt 16pt;
  text-align: left;
  justify-content: left;
  align-items: left;
`

const PricingCardContent = styled.div`
  display: inline-block;
  flex-glow: 1;
  align-items: stretch;
  justify-content: top;
  flex-direction: column;
  height: 1400px;
  width: 100%;

  @media (max-width: 1280px) {
    height: auto;
  }
`

const PricingCardFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48pt;
`

const PricingCard = styled.div`
  background-color: rgba(0, 0, 0, 0.7);
  border: rgba(255, 255, 255, 0.3);
  border-radius: 8pt;
  width: 300pt;
  display: inline-block;
  padding-bottom: 16pt;
  margin: 8pt;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 400pt) {
    width: 100%;
    margin: 8pt 0;
  }
  overflow: hidden;
`

const PromoContainer = styled.div`
  padding: 0 32px 0 8px;
  background-color: ${colors.trulyDark};
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  allign-items: middle;
  flex-layout: row;
  box-shadow: 0 0.25rem 4rem ${colors.rgba(colors.trulyDark, 0.2)};
  transition: all 0.2s;
  display: pointer;

  :hover {
    background: ${colors.accentPurple};
    transform: scale(1.1);
  }
`

const PricingCardIconWrapper = styled.div`
  padding: 16pt;
`

const PricingCardIcon = styled.img`
  height: 72pt;
  width: 72pt;
`

const PricingCardListElement = styled.li`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  font-weight: regular;
  font-size: 12pt;
  color: white;
  margin: 8px 0;
  padding: 0 32px;
  line-height: 16pt;
  font-style: italic;
`

const PricingCardListElementHeader = styled(PricingCardListElement)`
  font-weight: bold;
  margin: 8px 0 0;
  padding-top: 32px;
  opacity: 0.8;
`

const PricingCardListElementPricing = styled(PricingCardListElement)`
  font-weight: bold;
  font-size: 16pt;
  font-style: unset;

  margin: 4px 0 16px;
  padding-top: 10px;
  opacity: 0.8;
`

const PricingFeaturesList = styled.ul`
  list-style: none;

  margin: 0 0 1.5rem 0;
  padding: 1.5rem 0.5rem 0 0.5rem;
  width: 100%;

  @media (min-width: ${mobileBreakpoint}) {
    margin: 0 0 3rem 0;
    padding: 3rem 3rem 0 3rem;
  }
`

const PricingFeaturesListElement = styled.a`
  align-items: center;
  border-bottom: 1px solid ${colors.rgba(colors.trulyDark, 0.1)};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  font-weight: normal;
  padding: 8pt 0 6pt;

  :hover {
    background: #fafafa;
  }
`

const PricingCardTopSection = styled.div`
  background: rgba(0, 0, 0, 0.3);
  height: 316px;
  width: 100%;
`

const PricingHeader = styled(Large)`
  flex-grow: 1;
  overflow: hidden;
  font-size: 12pt;
  text-overflow: ellipsis;
  color: ${colors.trulyDark};
  text-align: left;
`

const PricingFeaturesListHeader = styled.div`
  border-bottom: 1px solid ${colors.rgba(colors.trulyDark, 0.3)};
  font-weight: bold;
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 4pt 0;
`

const PricingFeatureValid = styled.div`
  flex-shrink: 0;
  font-size: 12pt;
  text-align: center;
  width: 4.5rem;
  @media (min-width: 20.0625em) and (max-width: ${mobileBreakpoint}) {
    width: 6rem;
  }
  @media (min-width: ${mobileBreakpoint}) {
    width: 7rem;
  }
`

const PricingCardHeader = styled(PageParagraph)`
  font-size: 22pt;
  text-transform: uppercase;
  font-weight: bold;
`

const PricingCaret = styled(Caret)`
  margin-left: -28px;
  opacity: 0;
  transition: all 0.2s;
  transform: translate(-16px) rotate(-90deg);

  ${PricingFeaturesListElement}:hover & {
    opacity: 1;
    transform: translate(0px) rotate(-90deg);
    transition: all 0.2s;
  }
`

const PricingCardPricing = styled(PageParagraph)`
  font-size: 12pt;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;
`

const PricingCardPricingSmall = styled(PageParagraph)`
  font-size: 9pt;
  line-height: 12px;
  text-transform: uppercase;
  font-weight: bold;
  opacity: 0.6;
  margin-top: 4px;
`

const PricingFeatureBlue = styled(PricingFeatureValid)`
  color: ${colors.trulyBlue};
`

const PricingFeatureGreen = styled(PricingFeatureValid)`
  color: ${colors.trulyGreen};
`

const PricingFeaturePurple = styled(PricingFeatureValid)`
  color: ${colors.accentLavender};
`
